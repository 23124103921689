import { SearchWidget } from '@plone/volto/components';

export const DesktopFooterNav = () => {
  return (
    <div className="hidden sm:block lg:pr-[min(8vw,137px)] max-w-3xl">
      <SearchWidget className="border-solid border-[#FFBE00] border-2 dark:bg-[#00468C]" />
      <div className="grid grid-cols-3 gap-x-8 my-9 dark:[&_*]:text-[#F8FAFA]">
        <ul className="list-none flex flex-col gap-y-3 justify-self-start">
          <h5 className="text-xl md:text-2xl font-bold mb-4 text-[#0D1117]">Envolva-se</h5>
          <li className="font-dsBody">
            <a href="/comunidade" className="text-[#0D1117] dark:hover:text-[#FCBC00]">
              Comunidade
            </a>
          </li>
          <li className="font-dsBody">
            <a href="/contato" className="text-[#0D1117] dark:hover:text-[#FCBC00]">
              Contato
            </a>
          </li>
          <li className="font-dsBody">
            <a
              href="/cursos/estudenautfpr"
              className="text-[#0D1117] dark:hover:text-[#FCBC00]"
            >
              Ingresso
            </a>
          </li>
          <li className="font-dsBody">
            <a
              href="/institucional/conheca-os-campi"
              className="text-[#0D1117] dark:hover:text-[#FCBC00]"
            >
              13 Campi
            </a>
          </li>
          <li className="font-dsBody">
            <a
              href="/transparencia/transparencia-e-prestacao-de-contas"
              className="text-[#0D1117] dark:hover:text-[#FCBC00]"
            >
              Transparência e prestação de contas
            </a>
          </li>
          <li className="font-dsBody">
            <a
              href="/contato/ouvidoria/ouvidoria"
              className="text-[#0D1117] dark:hover:text-[#FCBC00]"
            >
              Ouvidoria
            </a>
          </li>
          <li className="font-dsBody">
            <a
              href="/contato/ouvidoria/perguntas-frequentes"
              className="text-[#0D1117] dark:hover:text-[#FCBC00]"
            >
              FAQ
            </a>
          </li>
        </ul>
        <ul className="list-none flex flex-col gap-y-3">
          <h5 className="text-xl md:text-2xl font-bold mb-4 text-[#0D1117]">
            Encontre aqui
          </h5>
          <li className="font-dsBody">
            <a
              href="/comunicacao/contato"
              className="text-[#0D1117] dark:hover:text-[#FCBC00]"
            >
              Área de imprensa
            </a>
          </li>
          {/* <li className="font-dsBody">
            <a href="#" className="text-[#0D1117] dark:hover:text-[#FCBC00]">
              Apoio estudantil
            </a>
          </li> */}
          <li className="font-dsBody">
            <a
              href="/alunos/bolsas/auxilio-estudantil"
              className="text-[#0D1117] dark:hover:text-[#FCBC00]"
            >
              Bolsas/auxílios
            </a>
          </li>
          {/* <li className="font-dsBody">
            <a href="#" className="text-[#0D1117] dark:hover:text-[#FCBC00]">
              Oportunidades
            </a>
          </li> */}
          <li className="font-dsBody">
            <a
              href="/editais/concursos"
              className="text-[#0D1117] dark:hover:text-[#FCBC00]"
            >
              Concursos
            </a>
          </li>
          <li className="font-dsBody">
            <a
              href="/diplomas"
              className="text-[#0D1117] dark:hover:text-[#FCBC00]"
            >
              Diplomas
            </a>
          </li>
        </ul>
        <ul className="dark:[&_*]:text-[#F8FAFA] list-none flex flex-col gap-y-3 justify-self-end">
          <h5 className="text-xl md:text-2xl font-bold mb-4 text-[#0D1117]">Gente nossa</h5>
          <li className="font-dsBody">
            <a href="#" className="text-[#0D1117] dark:hover:text-[#FCBC00]">
              Portais UTFPR
            </a>
          </li>
          <li className="font-dsBody">
            <a
              href="/servidores/servicos/ti/nuvem"
              className="text-[#0D1117] dark:hover:text-[#FCBC00]"
            >
              Nuvem
            </a>
          </li>
          <li className="font-dsBody">
            <a
              href="/servidores/servicos/ti/e-mail"
              className="text-[#0D1117] dark:hover:text-[#FCBC00]"
            >
              Webmail
            </a>
          </li>
          <li className="font-dsBody">
            <a
              href="/alunos/portal-do-aluno"
              className="text-[#0D1117] dark:hover:text-[#FCBC00]"
            >
              Portal do aluno
            </a>
          </li>
          <li className="font-dsBody">
            <a href="/alunos/moodle" className="text-[#0D1117] dark:hover:text-[#FCBC00]">
              Moodle
            </a>
          </li>
          <li className="font-dsBody">
            <a href="/login" className="text-[#0D1117] dark:hover:text-[#FCBC00]">
              Login
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}