//@ts-check
import React from 'react';
import { HiArrowRight } from 'react-icons/hi';
import { twJoin } from 'tailwind-merge';
import { CustomContainer } from '../general';
import { fixLink } from '../GridButtons/Views/fixLink';
import { NavLink } from 'react-router-dom';
//@ts-ignore
import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers';

const CheckInternal = ({ className, href, icon, children }) => {
  if (isInternalURL(href) && !href.includes('#')) {
    return (
      <NavLink
        className={className}
        to={flattenToAppURL(href)}
        target={twJoin(icon === '1' ? '_blank' : '_self')}
        rel="noreferrer"
      >
        {children}
      </NavLink>
    );
  }

  return (
    <a
      target={twJoin(icon === '1' ? '_blank' : '_self')}
      className={className}
      href={fixLink(href)}
      rel="noreferrer"
    >
      {children}
    </a>
  );
};

const HiperLinkContent = ({ name, link, icon, isEditMode }) => {
  let selectedIcon = 'rotate-0';

  const SetIcon = () => {
    switch (icon) {
      case '1':
        selectedIcon = '-rotate-45';
        break;
      case '2':
        selectedIcon = 'rotate-0';
        break;
      case '3':
        selectedIcon = 'hidden';
        break;
      default:
        selectedIcon = 'rotate-0';
    }
  };

  SetIcon();

  return (
    <CheckInternal
      href={link}
      icon={icon}
      className={twJoin(
        'group flex justify-center items-center relative gap-2',
        isEditMode && 'pointer-events-none',
      )}
    >
      <p className="text-sm font-dsButton font-semibold text-left text-dsBlue-600 m-0 group-hover:text-dsYellow-500 transition duration-300">
        {name}
      </p>
      <HiArrowRight
        className={`${selectedIcon} scale-[1.05] fill-dsBlue-600 group-hover:fill-dsYellow-500 transition duration-300`}
      />
    </CheckInternal>
  );
};

const HiperLinkView = (props) => {
  const { data } = props;

  let justify = 'justify-end';
  const SetAlignment = () => {
    switch (data.alignment) {
      case 'left':
        justify = 'justify-start';
        break;
      case 'center':
        justify = 'justify-center';
        break;
      case 'right':
        justify = 'justify-end';
        break;
      default:
        justify = 'justify-end';
    }
  };

  SetAlignment();

  return (
    <CustomContainer
      size={translateSize(data.container)}
      className={`flex flex-wrap gap-x-4 gap-y-2 ${justify}`}
      alignCenter={true}
    >
      {data.columns &&
        data.columns.map((item, index) => {
          return (
            <HiperLinkContent
              key={index}
              name={item.text || ''}
              link={item.link || '#'}
              icon={item.icon || '2'}
              isEditMode={props.isEditMode}
            />
          );
        })}
    </CustomContainer>
  );
};

/**
 *
 *
 * @returns {(import('../general/CustomContainers/types').Sizes)}
 * @param {'0'|'1'|'2'|'3'|undefined} size
 */
function translateSize(size) {
  switch (size) {
    case '0':
      return 'full-width';
    case '1':
      return 'ensino-institucional';
    case '2':
      return 'pesquisa-extensao';
    case '3':
      return 'pesquisa-institucional';
  }
  return 'ensino-institucional';
}

export default HiperLinkView;
