//@ts-check
import React from 'react';
//@ts-ignore
import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers';
import { IconsName } from '../../../assets/icons/buttons';
import { twJoin, twMerge } from 'tailwind-merge';
import { CustomContainer } from '../../general';
import { NavLink } from 'react-router-dom';
import { fixLink } from './fixLink';

const CheckInternal = ({ className, newTab, href, children }) => {
  if (isInternalURL(href) && !href.includes("#")) {
    return (
      <NavLink
        className={className}
        to={flattenToAppURL(href)}
        target={twJoin(newTab ? '_blank' : '_self')}
        rel="noreferrer"
      >
        {children}
      </NavLink>
    );
  } else {
    return (
      <a
        target={twJoin(newTab ? '_blank' : '_self')}
        className={className}
        href={fixLink(href)}
        rel="noreferrer"
      >
        {children}
      </a>
    );
  }
};

const Button = ({ title, icon, color, size, href, newTab, isEditMode }) => {
  const IconComponente = IconsName[icon];

  const iconColor = {
    black: '#000000',
    blue: '#005DB7',
  };

  return (
    <div className="flex flex-col sm:flex-row gap-x-6 gap-y-2 justify-start items-center">
      <CheckInternal
        className={twJoin(
          'group',
          (isEditMode || href === '#') && 'cursor-default pointer-events-none',
        )}
        newTab={newTab}
        href={href}
      >
        <IconComponente
          color={iconColor[color]}
          className={twJoin(
            'min-w-max w-[32px] h-[32px] shrink-0',
            size === '48px' && 'md:w-[48px] md:h-[48px]',
          )}
          changeColorOnHover={true}
        />
      </CheckInternal>
      {title && (
        <CheckInternal
          className={twMerge(
            'dark:text-[#F8FAFA] font-dsBody break-all sm:break-normal text-center sm:text-start',
            isEditMode || href === '#'
              ? 'cursor-default pointer-events-none'
              : 'hover:text-[#FCBC00] dark:hover:text-[#FCBC00] transition',
          )}
          newTab={newTab}
          href={href}
        >
          {title}
        </CheckInternal>
      )}
    </div>
  );
};

export const GridButtonsView = (props) => {
  const { data, isEditMode } = props;

  return (
    <CustomContainer
      size="pesquisa-extensao"
      alignCenter={true}
      className="px-4 sm:px-0 grid grid-cols-[repeat(auto-fit,minmax(150px,1fr))] gap-y-6 gap-x-8 md:gap-x-16"
    >
      {data.buttons?.map((btn, index) => {
        return (
          <Button
            key={index}
            title={btn.title}
            color={data.color ?? 'blue'}
            size={data.size ?? '48px'}
            icon={btn.icon ?? 'Default'}
            href={btn.link ?? '#'}
            newTab={btn.newTab ?? false}
            isEditMode={isEditMode}
          />
        );
      })}
    </CustomContainer>
  );
};
