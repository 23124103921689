import React from 'react';
import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers';
import { formatDate } from '../../../../helpers/Utils/Utils';
import { FiClock } from 'react-icons/fi';
import { CustomContainer } from '../../../../addons/utfpr_components/src/components/general';

const HighlightedTemplate = ({ items, linkTitle, linkHref, isEditMode }) => {
  const urlHandler = (url) => (isInternalURL(url) ? flattenToAppURL(url) : url);

  function openLink(link) {
    const url = urlHandler(link);
    window.location.href = url;
  }
  return (
    <CustomContainer
      className="mx-auto flex flex-col gap-3 pb-5"
      size="pesquisa-extensao"
    >
      {items.map((item) => (
        <div
          key={item['@id']}
          className="rounded border-l-[8px] border-[#FCBC00] dark:hover:border-dsBlue-50 cursor-pointer shadow-md hover:shadow-lg px-6 py-4 flex flex-col gap-3 transition dark:bg-[#001B3D] dark:hover:bg-[#003063] hover:text-[#0169CD]"
          onClick={() => {
            openLink(item.getURL);
          }}
        >
          <div className="text-[16px] dark:text-[#F8FAFA] font-bold">
            {item.title}
          </div>
          <div className="flex items-center gap-2">
            <FiClock className="dark:text-[#F8FAFA] text-[13px]" />
            <div className="text-[14px] text-[#8D9199] dark:text-[#F8FAFA]">
              {formatDate(item.CreationDate, 'dd/MM/yyyy HHhmm')}
            </div>
          </div>
        </div>
      ))}
    </CustomContainer>
  );
};
export default HighlightedTemplate;
