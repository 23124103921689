import { IconChevronDown } from '../../../../../../src/addons/utfpr_components/src/assets/icons/buttons/IconChevronDown'

export const NavMobile = () => {

  const ToggleActive = (e) => {
    if (e.currentTarget.classList.contains('ativo')) {
      e.currentTarget.classList.remove('ativo')
    } else {
      e.currentTarget.classList.add('ativo')
    }
  }

  return (
    <div className="flex flex-col">
      <button onClick={(e) => ToggleActive(e)} className="group/ensino peer/ensino px-12 py-4 flex justify-between"><h1 className="border-transparent pb-[6px] group-[.ativo]/ensino:border-dsYellow-400 group-[.ativo]/ensino:border-b-4 group-[.ativo]/ensino:border-solid title_large-web_bold text-left text-[rgb(70,70,70)] dark:text-[#F8FAFA]">Ensino</h1><IconChevronDown color="#464646" className="group-[.ativo]/ensino:rotate-180 dark:[&_path]:stroke-[#F8FAFA] transition" /></button>
      <ul className="peer-[.ativo]/ensino:flex hidden list-none flex-col">
        <li className="flex title_medium-tablet_semibold">
          <a title="Estude na UTFPR"
            href="/cursos/estudenautfpr" className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]">Estude na UTFPR</a>
        </li>
        <li className="flex title_medium-tablet_semibold">
          <a title="Graduação"
            href="/cursos/graduacao" className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]">Graduação</a>
        </li>
        <li className="flex title_medium-tablet_semibold">
          <a title="Pós-graduação"
            href="/pesquisa-e-pos-graduacao/cursos" className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]">Pós-graduação</a>
        </li>
        <li className="flex title_medium-tablet_semibold">
          <a title="Educação a distância"
            href="/cursos/educacao-a-distancia" className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]">Educação a distância</a>
        </li>
        <li className="flex title_medium-tablet_semibold">
          <a title="Outros cursos"
            href="/cursos/outros" className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]">Outros cursos</a>
        </li>
      </ul>
      <button onClick={(e) => ToggleActive(e)} className="group/pesquisa peer/pesquisa px-12 py-4 flex justify-between"><h1 className="border-transparent pb-[6px] group-[.ativo]/pesquisa:border-dsYellow-400 group-[.ativo]/pesquisa:border-b-4 group-[.ativo]/pesquisa:border-solid title_large-web_bold text-left text-[#464646] dark:text-[#F8FAFA]">Pesquisa</h1><IconChevronDown color="#464646" className="group-[.ativo]/pesquisa:rotate-180 dark:[&_path]:stroke-[#F8FAFA] transition" /></button>
      <ul className="peer-[.ativo]/pesquisa:flex hidden list-none flex-col">
        <li className="flex title_medium-tablet_semibold">
          <a title="Na graduação"
            href="/pesquisa-e-pos-graduacao/bolsas/pic" className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]">Na graduação</a>
        </li>
        <li className="flex title_medium-tablet_semibold">
          <a title="Mestrado e Doutorado"
            href="/pesquisa-e-pos-graduacao" className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]">Mestrado e Doutorado</a>
        </li>
        <li className="flex title_medium-tablet_semibold">
          <a title="Grupos de Pesquisa"
            href="/pesquisa-e-pos-graduacao/grupos-de-pesquisa" className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]">Grupos de Pesquisa</a>
        </li>
        <li className="flex title_medium-tablet_semibold">
          <a title="Laboratórios"
            href="/pesquisa-e-pos-graduacao/laboratorios-multiusuarios" className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]">Laboratórios</a>
        </li>
      </ul>
      <button onClick={(e) => ToggleActive(e)} className="group/inovacao peer/inovacao px-12 py-4 flex justify-between"><h1 className="border-transparent pb-[6px] group-[.ativo]/inovacao:border-dsYellow-400 group-[.ativo]/inovacao:border-b-4 group-[.ativo]/inovacao:border-solid title_large-web_bold text-left text-[#464646] dark:text-[#F8FAFA]">Inovação</h1><IconChevronDown color="#464646" className="group-[.ativo]/inovacao:rotate-180 dark:[&_path]:stroke-[#F8FAFA] transition" /></button>
      <ul className="peer-[.ativo]/inovacao:flex hidden list-none flex-col">
        <li className="flex title_medium-tablet_semibold">
          <a title="Agência de Inovação"
            href="/inovacao/agencia" className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]">Agência de Inovação</a>
        </li>
        <li className="flex title_medium-tablet_semibold">
          <a title="Política de Inovação"
            href="/inovacao/politica-de-inovacao" className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]">Política de Inovação</a>
        </li>
        <li className="flex title_medium-tablet_semibold">
          <a title="Empreendedorismo"
            href="/inovacao/empreendedorismo" className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]">Empreendedorismo</a>
        </li>
        <li className="flex title_medium-tablet_semibold">
          <a title="Incubadora"
            href="/inovacao/empreendedorismo/incubadora-de-inovacoes-da-utfpr" className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]">Incubadora</a>
        </li>
        <li className="flex title_medium-tablet_semibold">
          <a title="Parque tecnológico"
            href="/inovacao/parque-tecnologico" className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]">Parque Tecnológico</a>
        </li>
        <li className="flex title_medium-tablet_semibold">
          <a title="Empresa Júnior"
            href="/inovacao/empreendedorismo/empresa-junior" className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]">Empresa Júnior</a>
        </li>
        <li className="flex title_medium-tablet_semibold">
          <a title="Vitrine Tecnológica"
            href="/inovacao/vitrine-tecnologica" className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]">Vitrine Tecnológica</a>
        </li>
      </ul>
      <button onClick={(e) => ToggleActive(e)} className="group/extensao peer/extensao px-12 py-4 flex justify-between"><h1 className="border-transparent pb-[6px] group-[.ativo]/extensao:border-dsYellow-400 group-[.ativo]/extensao:border-b-4 group-[.ativo]/extensao:border-solid title_large-web_bold text-left text-[#464646] dark:text-[#F8FAFA]">Extensão</h1><IconChevronDown color="#464646" className="group-[.ativo]/extensao:rotate-180 dark:[&_path]:stroke-[#F8FAFA] transition" /></button>
      <ul className="peer-[.ativo]/extensao:flex hidden list-none flex-col">
        <li className="flex title_medium-tablet_semibold">
          <a title="Cursos Livres"
            href="/cursos/outros/extensao" className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]">Cursos Livres</a>
        </li>
        <li className="flex title_medium-tablet_semibold">
          <a title="Cultura"
            href="/cultura" className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]">Cultura</a>
        </li>
        <li className="flex title_medium-tablet_semibold">
          <a title="Ações Sociais"
            href="/" className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]">Ações Sociais</a>
        </li>
        <li className="flex title_medium-tablet_semibold">
          <a title="Extensão"
            href="/extensao" className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]">Extensão</a>
        </li>
        <li className="flex title_medium-tablet_semibold">
          <a title="Voluntariado"
            href="/" className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]">Voluntariado</a>
        </li>
      </ul>
      <button onClick={(e) => ToggleActive(e)} className="group/inst peer/inst px-12 py-4 flex justify-between"><h1 className="border-transparent pb-[6px] group-[.ativo]/inst:border-dsYellow-400 group-[.ativo]/inst:border-b-4 group-[.ativo]/inst:border-solid title_large-web_bold text-left text-[#464646] dark:text-[#F8FAFA]">Institucional</h1><IconChevronDown color="#464646" className="group-[.ativo]/inst:rotate-180 dark:[&_path]:stroke-[#F8FAFA] transition" /></button>
      <ul className="peer-[.ativo]/inst:flex hidden list-none flex-col">
        <li className="flex title_medium-tablet_semibold">
          <a title="Sobre a UTFPR"
            href="/institucional" className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]">Sobre a UTFPR</a>
        </li>
        <li className="flex title_medium-tablet_semibold">
          <a title="Quem é quem"
            href="/institucional/quem-e-quem" className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 dark:hover:bg-[#00468C] pl-14 py-7 hover:bg-dsYellow-50 focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]">Quem é quem</a>
        </li>
        <li className="flex title_medium-tablet_semibold">
          <a title="Estrutura"
            href="/estrutura" className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]">Estrutura</a>
        </li>
        <li className="flex title_medium-tablet_semibold">
          <a title="Documentos"
            href="/documentos" className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]">Documentos</a>
        </li>
        <li className="flex title_medium-tablet_semibold">
          <a title="Editais"
            href="/editais" className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]">Editais</a>
        </li>
        <li className="flex title_medium-tablet_semibold">
          <a title="Comissões"
            href="/comissoes" className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]">Comissões</a>
        </li>
        <li className="flex title_medium-tablet_semibold">
          <a title="Avaliação Institucional"
            href="/servicos-a-comunidade/avaliacao" className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 dark:hover:bg-[#00468C] pl-14 py-7 hover:bg-dsYellow-50 focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]">Avaliação Institucional</a>
        </li>
      </ul>
      <button onClick={(e) => ToggleActive(e)} className="group/inst peer/inst px-12 py-4 flex justify-between"><a href="/acesso-a-informacao" className="hover:text-[#464646] border-transparent pb-[6px] group-[.ativo]/inst:border-dsYellow-400 group-[.ativo]/inst:border-b-4 group-[.ativo]/inst:border-solid title_large-web_bold text-left text-[#464646] dark:text-[#F8FAFA]">Acesso à informação</a></button>
      <button onClick={(e) => ToggleActive(e)} className="group/inst peer/inst px-12 py-4 flex justify-between"><a href="/transparencia/transparencia-e-prestacao-de-contas" className="hover:text-[#464646] border-transparent pb-[6px] group-[.ativo]/inst:border-dsYellow-400 group-[.ativo]/inst:border-b-4 group-[.ativo]/inst:border-solid title_large-web_bold text-left text-[#464646] dark:text-[#F8FAFA]">Prestação de contas</a></button>
    </div>
  )
}