//@ts-check
import React from 'react';
import { CustomContainer } from '../general/CustomContainers/CustomContainer';

const TitleComponentView = (props) => {
  const { data } = props;

  let arrow = `before:border-t-4 before:w-6 before:mr-4 before:mb-1.5`;

  const tamanhoTravessaoMap = () => {
    switch (data.fontSize) {
      case '56px':
        arrow = 'before:border-t-8 before:w-16 before:mr-4 before:mb-4'; // w-16: 64px; t-8: 8px; mr-7: 28px;
        break;
      case '48px':
        arrow = 'before:border-t-8 before:w-12 before:mr-4 before:mb-3.5';
        break;
      case '32px':
        arrow = 'before:border-t-4 before:w-9 before:mr-4 before:mb-2.5';
        break;
      case '24px':
        arrow = 'before:border-t-4 before:w-7 before:mr-4 before:mb-2';
        break;
      case '22px':
        arrow = 'before:border-t-4 before:w-6 before:mr-4 before:mb-1.5';
        break;
      case '20px':
        arrow = 'before:border-t-4 before:w-6 before:mr-4 before:mb-1.5';
        break;
      default:
        arrow = 'before:border-t-4 before:w-6 before:mr-4 before:mb-1.5';
        break;
    }
  };

  tamanhoTravessaoMap();

  if (data.travessao) {
    return (
      <CustomContainer
        size={translateSize(data.container)}
        alignCenter={true}
        className="px-4"
      >
        <h1
          className={` before:content-[''] before:inline-block ${arrow} before:border-dsYellow-400 font-bold text-left text-dsGray-900 dark:text-[#F8FAFA]`}
          style={{ fontSize: data.fontSize }}
        >
          {data.title ?? 'Insira um texto para o título'}
        </h1>
      </CustomContainer>
    );
  }

  return (
    <CustomContainer
      size={translateSize(data.container)}
      alignCenter={true}
      className="px-4"
    >
      <h1
        className={`font-bold text-left text-dsGray-900 dark:text-[#F8FAFA]`}
        style={{ fontSize: data.fontSize }}
      >
        {data.title ?? 'Insira um texto para o título'}
      </h1>
    </CustomContainer>
  );
};

/**
 *
 *
 * @returns {(import('../general/CustomContainers/types').Sizes)}
 * @param {'0'|'1'|'2'|'3'|undefined} size
 */
function translateSize(size) {
  switch (size) {
    case '0':
      return 'full-width';
    case '1':
      return 'ensino-institucional';
    case '2':
      return 'pesquisa-extensao';
    case '3':
      return 'pesquisa-institucional';
  }
}

export default TitleComponentView;
