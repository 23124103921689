import View from './View';
import Edit from './Edit';
import teaserIcon from '../../assets/icons/teaser.svg';

export default {
  id: 'teaser',
  title: 'Teaser',
  icon: teaserIcon,
  group: 'common',
  view: View,
  edit: Edit,
  restricted: false,
  mostUsed: false,
  sidebarTab: 1,
  security: {
    addPermission: [],
    view: [],
  },
};
