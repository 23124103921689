import { IconHome } from '../../../../../addons/utfpr_components/src/assets/icons/buttons/IconHome'
import { IconSearch } from '../../../../../addons/utfpr_components/src/assets/icons/buttons/IconSearch'
import { IconUser } from '../../../../../addons/utfpr_components/src/assets/icons/buttons/IconUser'
import { IconHamburguer } from '../../../../../addons/utfpr_components/src/assets/icons/IconHamburguer'

import { ProfileMobile } from './ProfileMobile';
import { NavMobile } from './NavMobile';
import { SearchMobile } from './SearchMobile';
import { twJoin, twMerge } from 'tailwind-merge';
import { useHistory } from "react-router-dom";

import { useReducer } from 'react';

const reducer = (state, action) => {
  if (action.type === 'closeAll') {
    return {
      home: false,
      search: false,
      profile: false,
      nav: false,
      blurBg: false,
    };
  }
  if (action.type === 'toggleHome') {
    return {
      home: true,
      search: false,
      profile: false,
      nav: false,
      blurBg: false,
    };
  };
  if (action.type === 'toggleSearch') {
    return {
      home: false,
      search: !state.search,
      profile: false,
      nav: false,
      blurBg: state.search ? false : true,
    };
  };
  if (action.type === 'toggleProfile') {
    return {
      home: false,
      search: false,
      profile: !state.profile,
      nav: false,
      blurBg: state.profile ? false : true,
    };
  };
  if (action.type === 'toggleNav') {
    return {
      home: false,
      search: false,
      profile: false,
      nav: !state.nav,
      blurBg: state.nav ? false : true,
    };
  };
}

export const HeaderMobile = () => {

  const [state, dispatch] = useReducer(reducer, { home: false, search: false, profile: false, nav: false, blurBg: false });
  const history = useHistory();

  const toggleMenu = (action) => {
    dispatch({ type: action });
  }

  return (
    <div className="md:hidden">
      {state.blurBg && <div onClick={() => toggleMenu('closeAll')} style={{ backdropFilter: "blur(4px)" }} className="bg-black/20 w-screen h-screen inset-0 fixed z-40" />}
      {state.blurBg && <div className={twMerge("overflow-y-scroll py-7 px-4 fixed bottom-20 h-[75vh] w-full bg-[#F2F4F4] dark:bg-[#001C3A] z-50 rounded-t-xl", state.nav && 'px-0', state.search && 'h-[25vh] flex items-center')}>
        {state.profile && <ProfileMobile />}
        {state.nav && <NavMobile />}
        {state.search && <SearchMobile onSearch={() => dispatch({ type: 'toggleSearch' })} />}
      </div>}
      <div className="w-full fixed bottom-0 flex px-8 py-2 justify-between items-center h-20 z-50 shadow-[0px_-10px_45px_0px_rgba(0,0,0,0.20)] bg-white dark:bg-[#001C3A] dark:border-t dark:border-t-white">
        <button className="p-5 group" onClick={() => { toggleMenu('toggleHome'), history.push('/') }}>
          <IconHome className={twJoin("group-hover:[&_path]:stroke-[#464646] group-hover:dark:[&_path]:stroke-white", state.home && '[&_path]:stroke-[#464646] dark:[&_path]:stroke-white')} color="#C1C1C1" size={32} />
        </button>
        <button className="p-5 group" onClick={() => toggleMenu('toggleSearch')}>
          <IconSearch className={twJoin("group-hover:[&_path]:stroke-[#464646] group-hover:dark:[&_path]:stroke-white", state.search && '[&_path]:stroke-[#464646] dark:[&_path]:stroke-white')} color="#C1C1C1" size={32} />
        </button>
        <button className="p-5 group" onClick={() => toggleMenu('toggleProfile')}>
          <IconUser className={twJoin("group-hover:[&_path]:stroke-[#464646] group-hover:dark:[&_path]:stroke-white", state.profile && '[&_path]:stroke-[#464646] dark:[&_path]:stroke-white')} color="#C1C1C1" size={32} />
        </button>
        <button className="p-5 group" onClick={() => toggleMenu('toggleNav')}>
          <IconHamburguer className={twJoin("group-hover:[&_path]:stroke-[#464646] group-hover:dark:[&_path]:stroke-white", state.nav && '[&_path]:stroke-[#464646] dark:[&_path]:stroke-white')} color="#C1C1C1" size={32} />
        </button>
      </div>
    </div>
  )
}