import React, { useState } from 'react';
import Chip from '../../../general/Chip/Chip';
import { FiClock } from 'react-icons/fi';
import { ImagePlaceholder } from '../../../../assets/icons/buttons/ImagePlaceholder';

const MainNewsItemColumn = ({
  img,
  tags,
  title,
  link,
  description,
  date,
  editable,
  showImage,
}) => {
  const [imageIsVisible, setImageIsVisible] = useState(showImage);

  return (
    <a
      className="w-full group min-h-[210px] grid grid-cols-3 hover:text-dsBlue-400 gap-6 cursor-pointer bg-white dark:bg-[#001C3A] shadow rounded overflow-hidden"
      href={link}
      onClick={(event) => {
        editable ? event.preventDefault() : event;
      }}
    >
      <div className="flex group flex-col col-span-2 gap-2 p-6">
        <div className="flex group flex-col gap-3">
          <div className="text-[22px] group-hover:text-dsBlue-400 dark:group-hover:text-[#FCBC00] font-bold line-clamp-3 text-dsGray-900 dark:text-[#F8FAFA]">
            {title}
          </div>
          <p className="text-[#8D9199] text-[14px] flex flex-row gap-2 items-center ">
            <FiClock className="text-[14px]" />
            Publicado {date}, última modificação {date}
          </p>
        </div>
        <div className="text-[14px] text-[#0D1117] text-dsGray-900 dark:text-[#F8FAFA]">
          {description}
        </div>
        {tags.length > 0 && (
          <div className="flex justify-start items-start gap-2">
            {tags.map((tag, index) => {
              return <Chip key={index} text={tag} />;
            })}
          </div>
        )}
      </div>
      {imageIsVisible ? (
        <img
          src={img}
          alt={title}
          className="w-full h-full object-cover"
          loading="lazy"
          onError={() => setImageIsVisible(false)}
        />
      ) : (
        <div className="w-[270px] min-w-[270px] h-full flex items-center justify-center">
          <ImagePlaceholder
            color="#000000"
            className="dark:[&_path]:stroke-[#FFFFFF] group-hover:[&_path]:stroke-[#5699ff]"
          />
        </div>
      )}
    </a>
  );
};

export default MainNewsItemColumn;
