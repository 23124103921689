//@ts-check
import React from 'react';
import { urlImageHandler } from '../helpers';
import { urlHandler } from '../../../../../helpers/urlHandler';
import { CustomLink } from '../../../../general/CustomLink/CustomLink';

/**
 * @param {import('../../types').CellProps<"img">} props
 */
export const ImgCellView = (props) => {
  return (
    <>
      {props.attributes.url ? (
        <CustomLink
          className={`w-full object-cover h-full ${
            props.attributes.link ? '' : 'pointer-events-none'
          }`}
          openInNewWindow={props.attributes.openNewWindow}
          href={props.attributes.link ? props.attributes.link : '#'}
        >
          <img
            src={urlImageHandler(props.attributes.url)}
            className="w-full object-cover h-full"
            alt={props.text}
            title={props.text}
          ></img>
        </CustomLink>
      ) : (
        <span>{props.text}</span>
      )}
    </>
  );
};
