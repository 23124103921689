//@ts-check

import { SocialMediaEdit } from './SocialMediaEdit';
import { SocialMediaView } from './SocialMediaView';
//@ts-ignore
import IconFacebook from '../../assets/icons/svg/facebook.svg';

const config = {
  id: 'socialMedia',
  title: 'Mídias Sociais',
  group: 'media',
  view: SocialMediaView,
  edit: SocialMediaEdit,
  icon: IconFacebook,
  restricted: false,
  mostUsed: true,
  sidebarTab: 1,
};

export default config;
