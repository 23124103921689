//@ts-check
/** @type {import('./type').TableData}*/
export const defaultTableData = {
  rows: 2,
  cols: 2,
  cells: [
    [
      {
        type: 'text',
        position: { col: 0, row: 0 },
        attributes: { el: 'p' },
        text: '',
      },
      {
        type: 'text',
        position: { col: 1, row: 0 },
        attributes: { el: 'p' },
        text: '',
      },
    ],
    [
      {
        type: 'text',
        position: { col: 0, row: 1 },
        attributes: { el: 'p' },
        text: '',
      },
      {
        type: 'text',
        position: { col: 1, row: 1 },
        attributes: { el: 'p' },
        text: '',
      },
    ],
  ],
  // um valor padrão para altura de linha
  linesHeight: 'sm',
};

/**@type {import('./Cell/types').CellProps} */
export const defaultCell = {
  type: 'text',
  position: { col: 0, row: 0 },
  attributes: { el: 'p' },
  text: '',
};

/** @type {import("./store/type").SelectedCellT} */
export const defaultSelectedCell = null;

/** @type {(cell:(import("./store/type").SelectedCellT))=>any} */
export const defaultSetSelectedCell = (cell) => {};

/**
 *
 * @param {import('./type').TableData} table
 */
export const NonFunctionalSetterTableData = (table) => {};
